.payment-list {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.payment-list__loader {
  flex-grow: 1;
}

.payment-list__header {
  margin-bottom: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--smoked-20);
}

.payment-list__title {
  margin: 0 0 16px 0;
}

@media (max-width: 992px) {
  .payment-list {
    padding: 0 10px;
  }
}
