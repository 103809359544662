.slots-item-honeycomb {
  width: calc((100% - 36px)/ 4);
  margin: 0 12px 12px 0;
}

.slots-item-honeycomb:nth-child(4n) {
  margin-right: 0;
}

.slots-item-honeycomb__label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80px;
  border-radius: 8px;
  border: 1px solid var(--blueberry-40);
  cursor: pointer;
}

.slots-item-honeycomb__label:hover {
  border: 1px solid var(--blueberry);
}

.slots-item-honeycomb__description {
  color: var(--blueberry);
}

.slots-item-honeycomb__input {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: fixed;
  white-space: nowrap;
  width: 1px;
}

.slots-item-honeycomb__input:checked ~ .slots-item-honeycomb__label {
  color: var(--white);
  background-color: var(--blueberry);
}

.slots-item-honeycomb__input:checked ~ .slots-item-honeycomb__label .slots-item-honeycomb__description {
  color: inherit;
}

.keyboard-user .slots-item-honeycomb__input:focus ~ .slots-item-honeycomb__label {
  border-color: var(--blueberry);
  box-shadow: 0 0 2px 2px var(--blueberry-40);
}

.keyboard-user .slots-item-honeycomb__input:checked:focus ~ .slots-item-honeycomb__label {
  border-color: var(--white);
}

.slots-item-honeycomb__input:disabled ~ .slots-item-honeycomb__label {
  color: var(--smoked-40);
  background-color: var(--white-cream-light);
  border-color: transparent;
  cursor: not-allowed;
}
