.menu-item {
  position: relative;
  color: var(--smoked-70);
  transition: color .2s ease-in;
}

.menu-item:not(:last-child) {
  margin-right: 2.5rem;
}

.menu-item.active,
.menu-item:hover {
  color: var(--black);
}

.menu-item::after {
  content: '';
  position: absolute;
  bottom: -.5rem;
  left: 50%;
  right: 50%;
  border-radius: 2px;
  height: 0.25rem;
  background-color: var(--cucumber);
  transition: all .2s ease-in;
  opacity: 0;
}

.menu-item.active::after,
.menu-item:hover::after {
  opacity: 1;
  right: 0;
  left: 0;
}



@media (max-width: 992px) {
  .menu {
    display: flex;
  }
}