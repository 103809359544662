.rich-title {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
}

.rich-title .rich-title-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 32px;
}

.rich-title .rich-title-label:focus {
  outline: none;
}

.rich-title .ui-icon {
  color: var(--blueberry);
}

.rich-title p {
  margin: 0;
}

.rich-title .button {
  width: 80px;
}

.rich-title__subtitle {
  position: absolute;
  bottom: 8px;
  color: var(--smoked-70);
}
