@font-face {
  font-family: 'shopIcons';
  src:  url('../assets/fonts/shopIcons.eot?l35luy');
  src:  url('../assets/fonts/shopIcons.eot?l35luy#iefix') format('embedded-opentype'),
    url('../assets/fonts/shopIcons.ttf?l35luy') format('truetype'),
    url('../assets/fonts/shopIcons.woff?l35luy') format('woff'),
    url('../assets/fonts/shopIcons.svg?l35luy#shopIcons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'shopIcons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-exclamationmark-bubble:before {
  content: "\e925";
}
.icon-big-chevron:before {
  content: "\e900";
}
.icon-truck-28:before {
  content: "\e901";
}
.icon-products-28:before {
  content: "\e902";
}
.icon-preparation-28:before {
  content: "\e903";
}
.icon-plus-28:before {
  content: "\e904";
}
.icon-spinner:before {
  content: "\e905";
}
.icon-pin-28:before {
  content: "\e906";
}
.icon-pin-16:before {
  content: "\e907";
}
.icon-phone-28:before {
  content: "\e908";
}
.icon-orders-28:before {
  content: "\e909";
}
.icon-notifications-28:before {
  content: "\e90a";
}
.icon-minus-28:before {
  content: "\e90b";
}
.icon-location-16:before {
  content: "\e90c";
}
.icon-info-16:before {
  content: "\e90d";
}
.icon-home-28:before {
  content: "\e90e";
}
.icon-googlemaps-16:before {
  content: "\e90f";
}
.icon-forward-28:before {
  content: "\e910";
}
.icon-faq-28:before {
  content: "\e911";
}
.icon-edit-28:before {
  content: "\e912";
}
.icon-edit-16:before {
  content: "\e913";
}
.icon-delete-28:before {
  content: "\e914";
}
.icon-delete-16:before {
  content: "\e915";
}
.icon-cross-28:before {
  content: "\e916";
}
.icon-confirmed-28:before {
  content: "\e917";
}
.icon-chevron-up:before {
  content: "\e918";
}
.icon-chat-28:before {
  content: "\e919";
}
.icon-card-28:before {
  content: "\e91a";
}
.icon-back-28:before {
  content: "\e91b";
}
.icon-alert-mono-16:before {
  content: "\e91c";
}
.icon-account-28:before {
  content: "\e91d";
}
.icon-logout-28:before {
  content: "\e91e";
}
.icon-right-arrow:before {
  content: "\e91f";
}
.icon-card-16:before {
  content: "\e920";
}
.icon-chevron-down-16:before {
  content: "\e921";
}
.icon-cart-28:before {
  content: "\e922";
}
.icon-disclosure-down:before {
  content: "\e923";
}
.icon-categories-grouceries-16:before {
  content: "\e924";
}
.icon-check-28:before {
  content: "\cec28";
}
.icon-chevron-right-16:before {
  content: "\e926";
}
.icon-close-16:before {
  content: "\e927";
}
.icon-rating-28:before {
  content: "\e928";
}
.icon-lock-16:before {
  content: "\e929";
}
.icon-download-28:before {
  content: "\e92a";
}
.icon-share-16:before {
  content: "\e92b";
}
.icon-hamburger-28:before {
  content: "\e92c";
}
.icon-location-162:before {
  content: "\e92e";
}
.icon-pass-hidden-16:before {
  content: "\e930";
}
.icon-pass-shown-16:before {
  content: "\e931";
}
.icon-star-16:before {
  content: "\e935";
}
.icon-oval-plus-28:before {
  content: "\e937";
}
.icon-search-28:before {
  content: "\e938";
}
