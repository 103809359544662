.empty-shopping-list__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.empty-shopping-list__description {
  text-align: center;
  color: var(--black);
}

.empty-shopping-list__image {
  margin-top: 40px;
  margin-bottom: 32px;
  padding: 0px 10px;
}

.empty-shopping-list__content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 381px;
}
