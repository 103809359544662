.element-cell {
  position: relative;
  padding-left: 80px;
  margin-top: 16px;
  display: flex;
  box-shadow: var(--shadow-default);
  background-color: var(--white);
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.element-cell__buttons {
  margin-top: 24px;
  display: flex;
}

.element-cell__buttons .ui-button:not(:last-of-type) {
  margin-right: 8px;
}
