.country-code-item {
  padding: 11px 7px;
  font-size: 0.875rem;
  cursor: pointer;
  outline: none;
}

.country-code-item:first-of-type {
  margin-top: 8px;
}

.country-code-item:last-of-type {
  margin-bottom: 8px;
}

.country-code-item.active {
  background-color: var(--white-cream);
}
