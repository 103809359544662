.overlay {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.4s ease;
}

.overlay.overlay--show {
  opacity: 1;
  height: 100%;
  background: var(--smoked-40);
  transition: opacity 0.4s ease;
}

.overlay .overlay__background {
  width: 100%;
  height: 100%;
}
