.password-recovery {
  text-align: left;
  margin-bottom: 16px;
}

.password-recovery .password-recovery__title {
  margin: 0 0 24px;
}

.password-recovery .password-recovery__message {
  margin-bottom: 24px;
  line-height: 1.5;
}
