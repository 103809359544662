.choice-step {
  height: auto;
  margin-bottom: 32px;
}

@media (min-width: 480px) {
  .choice-step {
    height: 288px;
    overflow: auto;
  }
}
