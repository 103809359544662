.ui-scrollable-modal .ui-modal-content {
  width: 544px;
  padding: 32px;
}

.ui-scrollable-modal__title {
  text-align: left;
  margin: 0;
}

.ui-scrollable-modal__content {
  padding: 32px 0;
}

.ui-scrollable-modal__actions {
  display: flex;
  gap: 12px;
}