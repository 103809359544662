.payment-cell__info {
  display: inline-flex;
  align-items: center;
  height: 32px;
}

.payment-cell__info .card-mask {
  margin-right: 16px;
}

.payment-cell__info .payment-card-icon {
  position: absolute;
  top: 16px;
  left: 16px;
  margin-right: 16px;
}
