.order-status {
  margin-top: 24px;
  display: flex;
}

.order-status .order-status__labels {
  max-width: 340px;
  margin-left: 16px;
}

.order-status .order-status__labels .title {
  padding-bottom: 32px;
}

.order-status__countdown-wrapper {
  margin-top: 0.31rem;
  margin-bottom: 1.3rem;
}

.order-status .order-status__labels .title--active {
  color: var(--blueberry);
  padding-bottom: 0;
}

.order-status .order-status__labels .title--disable {
  color: var(--smoked-40);
}

.order-status .order-status__labels .title--disrupted {
  color: var(--orange);
}

.order-status .order-status__labels .title--disable:last-of-type {
  padding-bottom: 27px;
}

.order-status .order-status__labels .title--canceled {
  color: var(--tomato);
  padding-bottom: 0;
}

.order-status .order-status__labels .order-status__labels-text span,
.order-status .order-status__labels .order-status__labels-text {
  margin-top: 5px;
  padding-bottom: 20px;
  height: 56px;
  font-size: 0.75rem;
}

.order-status .order-status__labels .order-status__labels-text span a,
.order-status .order-status__labels .order-status__labels-text a {
  margin-right: 4px;
}

.order-status .order-status__labels .order-status__labels-text a span {
  color: var(--black);
  cursor: pointer;
}

.order-status .order-status__labels .order-status__labels-text .download {
  color: var(--cucumber);
  cursor: pointer;
}

.order-status .order-status__labels .button {
  width: auto;
  height: 32px;
  padding: 0 12px;
  line-height: 32px;
  margin-bottom: 16px;
}

.order-status .order-status-bullet {
  width: 24px;
  height: 219px;
}

.order-status .order-status-bullet--countdown {
  width: 24px;
  height: 255px;
}

.order-status .order-status-bullet--countdown.order-status-bullet_confirmed {
  background: url('./assets/status-on-going-countdown.svg') no-repeat;
}

.order-status .order-status-bullet.order-status-bullet_confirmed {
  background: url('./assets/status-on-going.svg') no-repeat;
}

.order-status .order-status-bullet.order-status-bullet_preparing,
.order-status .order-status-bullet.order-status-bullet_prepared {
  background: url('./assets/status-preparation.svg') no-repeat;
}

.order-status .order-status-bullet.order-status-bullet_delivering {
  background: url('./assets/status-delivering.svg') no-repeat;
}

.order-status .order-status-bullet.order-status-bullet_delivered {
  background: url('./assets/status-delivered.svg') no-repeat;
}

.order-status .order-status-bullet.order-status-bullet_cancelled {
  background: url('./assets/status-cancel.svg') no-repeat;
}

.order-status .order-status-bullet.order-status-bullet_disrupted {
  background: url('./assets/status-disrupted.svg') no-repeat;
}

.order-status .order-status-bullet.order-status-bullet_disrupted_action {
  background: url('./assets/status-disrupted-action.svg') no-repeat;
}

.order-status .payment-form {
  margin-top: 0;
}

.order-status .waiting-response {
  height: 168px;
}
