.payment-form {
  width: 100%;
  display: flex;
  background-color: var(--white);
  border-radius: 8px;
  margin-top: 17px;
  padding: 24px;
  height: 282px;
}

.payment-form .payment-iframe {
  border: 0;
  min-height: 219px;
}

.payment-form .payment-info {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.payment-form .payment-info button {
  display: flex;
  align-self: flex-start;
}

.payment-form .payment-methods {
  margin-left: 32px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.payment-form .payment-methods .payment-methods__title {
  margin-top: 8px;
}

.payment-form .payment-methods .payment-methods__cards {
  margin-top: 16px;
  height: 26px;
  width: 148px;
}

.payment-form .payment-methods .payment-methods__disclaimer {
  font-size: 0.625rem;
  color: var(--smoked-70);
}

.payment-form__tokenization-alert {
  margin-top: 16px;
}

@media (min-width: 992px) {
  .payment-form .payment-iframe {
    min-width: 295px;
  }
}

@media (min-width: 1200px) {
  .payment-form .payment-iframe {
    min-width: 330px;
  }
}

.payment-form__disclaimer {
  color: var(--smoked-70);
  display: flex;
  justify-content: center;
  margin: 16px 0;
}

.payment-form__disclaimer-message {
  margin-left: 8px;
}
