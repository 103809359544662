.arrow {
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 56px;
  border-radius: 4px;
  background-color: var(--smoked-8);
  color: var(--smoked-40);
  cursor: pointer;
  display: block;
  position: absolute;
  font-size: 2rem;
  padding: 12px 6px;
  box-sizing: border-box;
}

.arrow.arrow--left {
  transform: rotateY(180deg);
}

.arrow:hover {
  opacity: 0.7;
  background-color: var(--smoked-20);
  color: var(--smoked-70);
}

.arrow.hidden {
  display: none;
}

.arrow.disabled {
  cursor: not-allowed;
  background-color: transparent;
  color: var(--smoked-20);
}
