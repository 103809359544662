.password-recovery-layout {
  width: 350px;
  display: flex;
  flex-direction: column;
  align-content: center;
  margin: 64px auto;
  padding: 0 20px;
}

.password-recovery-layout .password-recovery-layout__logo {
  width: 230px;
  height: 32px;
}

.password-recovery-layout .password-recovery-layout__title {
  margin: 32px 0 16px;
}

.password-recovery-layout .password-recovery-layout__description {
  color: var(--smoked-70);
  margin-bottom: 24px;
  width: 260px;
}

.password-recovery-layout .password-recovery-layout__form .input-text {
  margin: 8px 0 26px;
}

.password-recovery-layout
  .password-recovery-layout__form
  .input-password
  .input-text {
  margin-bottom: 0;
}

.password-recovery-layout
  .password-recovery-layout__form
  .input-password
  .input-text__message.input-text__message--error {
  display: none;
}

.password-recovery-layout .password-recovery-layout__form .button {
  margin-top: 24px;
}

.password-recovery-layout .password-recovery-layout__contact {
  margin: 24px 0;
  border-top: 1px solid var(--white-cream);
  padding-top: 16px;
  color: var(--smoked-40);
  line-height: 1.33;
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
}

.password-recovery-layout .password-recovery-layout__contact p:hover {
  color: var(--cucumber);
  cursor: pointer;
}
