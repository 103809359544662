.order-payment-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid rgba(230, 230, 230, 1);
  cursor: pointer;
  width: 100%;
  height: 60px;
}
.order-payment-cell[disabled] {
  cursor: auto;
}

.order-payment-cell .order-payment-cell__name {
  display: flex;
  align-items: center;
}

.order-payment-cell .order-payment-cell__name .payment-card-icon {
  margin-right: 16px;
}

.order-payment-cell__name .ui-icon {
  width: 48px;
  height: 48px;
  margin-right: 10px;
}

.order-payment-cell__name .card-expiration-status {
  font-size: 14px;
  margin-left: 8px;
}

.order-payment-cell .order-payment-cell__check {
  width: 28px;
  height: 28px;
  border: 0.5px solid rgba(230, 230, 230, 1);
  border-radius: 14px;
}

.order-payment-cell__check .ui-icon {
  color: var(--white);
  font-size: 28px;
}

.order-payment-cell__check.active {
  background-color: var(--cucumber);
  border-color: var(--cucumber);
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-payment-cell:focus {
  outline: none;
}

.order-payment-cell:focus .order-payment-cell__check {
  border-color: var(--smoked-70);
}
