.ui-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--smoked-40);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 6;
}

.ui-modal-content {
  position: relative;
  display: inline-block;
  border-radius: 6px;
  background-color: var(--white);
  box-shadow: var(--shadow-hard);
  padding: 48px 40px 32px;
}
