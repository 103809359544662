.slots-calendar-day {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 56px;
  height: 56px;
}

.slots-calendar-day__input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

.slots-calendar-day__input:focus {
  outline: none;
}

.slots-calendar-day__label {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: solid 1px var(--blueberry-40);
  color: var(--black);
  cursor: pointer;
}

.slots-calendar-day__input:hover + .slots-calendar-day__label {
  border: solid 1px var(--blueberry);
}

.slots-calendar-day__input:checked + .slots-calendar-day__label {
  color: var(--white);
  background-color: var(--blueberry);
}

.slots-calendar-day__input:not(:checked):disabled + .slots-calendar-day__label {
  color: var(--smoked-40);
  border: none;
  cursor: not-allowed;
}

.keyboard-user
  .slots-calendar-day__input:checked:focus
  + .slots-calendar-day__label {
  border-color: var(--white);
}

.keyboard-user .slots-calendar-day__input:focus + .slots-calendar-day__label {
  border-color: var(--blueberry);
  box-shadow: 0px 0px 2px 2px var(--blueberry-40);
}

.slots-calendar-day__name {
  margin-top: -4px;
}
