.input-password {
  position: relative;
}

.input-password__show-password {
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 2;
}

.input-password .icon--empty {
  color: var(--smoked-20);
  pointer-events: none;
}

.input-password .icon--shown {
  color: var(--cucumber);
  cursor: pointer;
}

.input-password .icon--hidden {
  cursor: pointer;
  color: var(--smoked-40);
}
