.add-element {
  background-color: var(--white-cream-light);
  border: 1px solid transparent;
  height: 64px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--smoked-40);
  width: 100%;
  transition: all 0.1s linear;
}

.add-element:hover,
.add-element:active {
  color: var(--cucumber);
  box-shadow: var(--shadow-card-1);
  border: 1px solid #e6e6e6;
  background-color: var(--white);
}

.add-element__icon {
  font-size: 28px;
  margin-right: 8px;
}

.add-element__text {
  font-size: 16px;
  font-weight: 600;
}
