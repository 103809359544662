.purchase-confirmation__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.purchase-confirmation__details h1 {
  margin-bottom: 8px;
}

.purchase-confirmation__email {
  margin-bottom: 32px;
}

.purchase-confirmation__email p {
  color: var(--smoked-70);
}

.purchase-confirmation__delivery-date {
  margin: 8px 0 16px;
}

hr.purchase-confirmation__separator {
  border: 0.5px solid var(--white-cream);
  width: 100%;
  margin: 40px 0 24px;
}

.purchase-confirmation__image {
  width: 288px;
  height: 234px;
  margin-right: 48px;
}

.purchase-confirmation__price {
  display: flex;
  margin-top: 32px;
}

.purchase-confirmation__details .tooltip {
  top: 2px;
}

.purchase-confirmation__details .tooltip:focus .ui-icon {
  outline: none;
}

.purchase-confirmation__details .tooltip .top {
  bottom: 25px;
  margin-left: -171px;
}

.purchase-confirmation__details .tooltip .ui-icon {
  cursor: pointer;
  margin-left: 8px;
  color: var(--cucumber);
}

.purchase-confirmation__footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.purchase-confirmation__footer p:first-child {
  width: 40%;
  color: var(--smoked-70);
}

.purchase-confirmation__footer button {
  width: 215px;
}

.purchase-confirmation__footer button:first-child {
  margin-right: 12px;
}
