.text-area {
  margin-bottom: 18px;
  border-radius: 3px;
  border: 1px solid var(--smoked-20);
  padding: 14px 10px 5px;
}

.text-area .text-area__label {
  color: var(--smoked-40);
  font-size: 0.875rem;
  transition: transform 0.2s ease-out;
  transform-origin: 0 0;
  display: block;
  cursor: text;
}

.text-area .text-area__input {
  width: 100%;
  outline: none;
  caret-color: var(--cucumber);
  resize: none;
  font-size: 0.875rem;
  border: none;
  padding: 0;
  display: block;
  -ms-overflow-style: none;
}

.text-area .text-area__counter {
  color: var(--smoked-40);
  font-size: 0.7rem;
  text-align: right;
  display: block;
}

.text-area .text-area__counter.text-area__counter--error {
  color: var(--tomato);
}

.text-area.text-area--active .text-area__input {
  transform: translateY(-5px);
}

.text-area.text-area--active .text-area__label {
  cursor: auto;
  color: var(--smoked-70);
  transform: translateY(-7px) scale(0.75);
}
