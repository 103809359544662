.drop-down .drop-down__trigger {
  outline: none;
}
.drop-down .dropdown__content {
  opacity: 1;
  animation-name: show_dropdown;
  animation-duration: 0.2s;
  position: relative;
}

@keyframes show_dropdown {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
