.section-carousel {
  padding: 1rem 0;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--smoked-20);
}

.section-carousel__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0.75rem 1rem;
}

.section-carousel__title {
  margin: 0 0 0.25rem;
}

.section-carousel__subtitle {
  color: var(--smoked-70);
}

.section-carousel__link {
  display: flex;
  align-items: center;
  color: var(--cucumber);
}

.section-carousel__link .icon {
  font-size: 1rem;
  margin-left: 0.25rem;
}

.section-carousel .product-container {
  display: flex;
  flex-flow: row wrap;
}

@media (min-width: 480px) {
  .section-carousel {
    max-width: calc(var(--max-cell-width) * 3);
  }

  .section-carousel .product-cell:nth-child(n) {
    display: block;
  }

  .section-carousel .product-cell:nth-child(n + 4) {
    display: none;
  }
}

@media (min-width: 992px) {
  .section-carousel {
    max-width: calc(var(--max-cell-width) * 4);
  }

  .section-carousel .product-cell:nth-child(n) {
    display: block;
  }

  .section-carousel .product-cell:nth-child(n + 5) {
    display: none;
  }
}

@media (min-width: 1200px) {
  .section-carousel {
    max-width: calc(var(--max-cell-width) * 5);
  }

  .section-carousel .product-cell:nth-child(n) {
    display: block;
  }

  .section-carousel .product-cell:nth-child(n + 6) {
    display: none;
  }
}

@media (min-width: 1440px) {
  .section-carousel {
    max-width: calc(var(--max-cell-width) * 6);
  }

  .section-carousel .product-cell:nth-child(n) {
    display: block;
  }

  .section-carousel .product-cell:nth-child(n + 7) {
    display: none;
  }
}
