.inaccurate-address-modal  .modal-content{
    padding: 52px 80px 32px 80px;
    width: 554px;
}

.inaccurate-address-modal__image {
    width: 120px;
    height: 120px;
}

.inaccurate-address-modal__button {
    margin-top: 24px;
    width: 224px;
    font-weight: 600;
}

.inaccurate-address-modal__description {
    color: var(--smoked-70);
}

.inaccurate-address-modal__content {
    padding: 0px 8px;
}