.edit-products-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.edit-products-placeholder__img {
  margin-top: 5rem;
  width: 136px;
  height: auto;
}

.edit-products-placeholder__message {
  width: 273px;
  margin-top: 2rem;
  color: var(--smoked-70);
  text-align: center;
}
