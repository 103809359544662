:root {
  --highlighted-product-item_left-section-padding: 40px;
}

.highlighted-product-item {
  height: 320px;
  min-width: auto;
  width: 100%;
  max-width: 1298px;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
}

.highlighted-product-item__title {
  margin: 0;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.highlighted-product-item__left {
  width: 498px;
  padding: 33px 16px 33px var(--highlighted-product-item_left-section-padding);
  color: var(--black);
  width: 50%;
}

.highlighted-product-item__wrapper {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.highlighted-product-item__clickable {
  cursor: pointer;
}

.highlighted-product-item__add-to-cart-section {
  position: absolute;
  width: 192px;
  bottom: 0px;
  margin-bottom: 40px;
  margin-left: var(--highlighted-product-item_left-section-padding);
}

.highlighted-product-item__image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
}

.highlighted-product-item__image {
  object-fit: contain;
  width: 100%;
  height: 100%;
  min-width: 320px;
}
