.pop-over {
  width: 276px;
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: var(--shadow-hard);
  position: relative;
  display: flex;
  flex-flow: row wrap;
  top: 100%;
  padding: 16px;

}

.pop-over.pop-over--left::after,
.pop-over.pop-over--right::after {
  content: '';
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-bottom-color: var(--white);
  border-top: 0;
  margin-left: -8px;
  margin-top: -8px;
}

.pop-over.pop-over--right::after {
  right: 16px;
}

.pop-over.pop-over--left::after {
  left: 16px;
}

.pop-over__title {
  margin: 0;
  flex: 1;
}

.pop-over__message {
  margin: 4px 0 16px 0;
}

.pop-over__close {
  display: flex;
  color: var(--smoked-70);
  cursor: pointer;
}
