.category-detail {
  width: 100%;
  padding-top: 2rem;
}

.category-detail__content {
  display: flex;
  flex-direction: column;
}

.category-detail .section__header {
  margin-bottom: 16px;
}

.category-detail__title {
  justify-self: flex-start;
  width: 100%;
  margin-left: 12px;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--smoked-20);
}

.category-detail__name h3 {
  padding-bottom: .5rem;
  border-bottom: 0.5px solid var(--smoked-20);
  margin: 0 12px 16px;
  font-size: 2rem;
}

.category-detail .section h3 {
  font-weight: 600;
}

.category-detail__next-subcategory {
  margin: 24px auto 40px;
}

@media (max-width: 992px) {
  .category-detail {
    margin-left: 10px;
  }

  .grid-layout__sidebar {
    position: static;
  }
}
