.shopping-list-item__wrapper {
  display: flex;
  width: 400px;
  height: 120px;
  justify-content: start;
  border: 1px solid var(--white-cream);
  padding: 12px;
  border-radius: 8px;
  transition: .2s box-shadow ease-in;
}

.shopping-list-item__wrapper:hover {
  box-shadow: var(--shadow-card-2)
}

.shopping-list-item__title {
  color: var(--black);
  overflow-wrap: break-word;
  width: 240px;
}

.shopping-list-item__title::first-letter {
  text-transform: capitalize;
}

.shopping-list-item__quantity {
  color: var(--smoked-70);
}

.shopping-list-item__list-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 45px;
  align-self: center;
}

.shopping-list-item__product-image-wrapper {
  min-height: 96px;
  min-width: 96px;
  margin-right: 32px;
  display: grid;
  grid-template-columns: 48px 48px;
  grid-column-gap: 3px;
  grid-row-gap: 1px;
  align-content: center;
  justify-items: center;
  border-radius: 4px;
  position: relative;
}

.shopping-list-item__product-image-wrapper .product-cell__image-overlay {
  border-radius: 4px;
}

.shopping-list-item__product-image {
  height: 42px;
}

.shopping-list-item__placeholder {
  height: 36px;
}

.shopping-list-item__image-empty--position1 {
  margin-left: 9px;
  margin-top: 2px;
}

.shopping-list-item__image-empty--position2 {
  margin-right: 7px;
  margin-top: 2px;
}

.shopping-list-item__image-empty--position3 {
  margin-left: 9px;
  margin-top: 4px;
}

.shopping-list-item__image-empty--position4 {
  margin-right: 7px;
  margin-top: 4px;
}

.shopping-list-item__image--position1 {
  margin-left: 8px;
}

.shopping-list-item__image--position2 {
  margin-right: 6px;
}

.shopping-list-item__image--position3 {
  margin-left: 8px;
}

.shopping-list-item__image--position4 {
  margin-right: 6px;
}
