.check-user {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.check-user img {
  height: 26px;
}

.check-user h4 {
  margin: 40px 0 16px;
}

.check-user label {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
}

.check-user .input-text {
  margin-bottom: 34px;
  width: 100%;
}

.check-user .input-text .input-text__label {
  padding-top: 14px;
}

.check-user .ui-button {
  margin-bottom: 16px;
}
