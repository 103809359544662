.address-confirmation-modal-label {
    position: absolute;
    z-index: 1;
    width: calc(100% - 96px);
    text-align: center;
  }
  
  .address-confirmation-modal-label__info {
    height: 24px;
    display: flex;
    align-items: center;
    width: fit-content;
    margin: 12px auto;
    padding: 2px 8.5px;
    border-radius: 2px;
    background-color: var(--blueberry);
    color: var(--white);
    box-shadow: var(--shadow-label);
    overflow: hidden;
  }
