.shopping-lists-search-products-dialog {
  justify-content: flex-end;
}

.shopping-lists-search-products-dialog .modal-content {
  height: calc(100vh - 200px);
  width: 592px;
  border-radius: 6px 6px 0 0;
}

.shopping-list-search-products-content__wrapper {
  padding: 32px 32px 0 32px;
  max-height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

.shopping-list-search-products-content__results {
  max-height: fit-content;
  overflow-y: scroll;
  padding-bottom: 20px;
}

.shopping-list-search-products-content__tile {
  text-align: left;
  margin-bottom: 24px;
  margin-top: unset;
}

.shopping-list-search-products-content__search-input {
  width: 100%;
  margin-bottom: 8px;
}

.shopping-list-search__result-wrapper .product-price {
  margin-bottom: 0px;
}

.shopping-list-search-products-content__search-input .search .search__input {
  width: calc(100% - 76px);
}

.shopping-list-search-products-content__search-input .search .search__button {
  left: 12px;
}

.shopping-list-search-products-content__search-input .search .search__close {
  right: 12px;
}

.shopping-list-search-products-content__search-input .search {
  max-width: unset;
  width: unset;
}

.shopping-list-search-products-content__results-empty-bottom-placeholder {
  height: 32px;
}

@media screen and (max-height: 790px) {
  .shopping-lists-search-products-dialog .modal-content {
    height: calc(100vh - 70px);
  }
}

@media screen and (min-height: 1280px) {
  .shopping-lists-search-products-dialog {
    justify-content: center;
  }

  .shopping-lists-search-products-dialog .modal-content {
    height: 1200px;
    border-radius: 6px;
  }
}
