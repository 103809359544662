.token-authn-payment-form {
  display: flex;
  margin-bottom: 16px;
  height: 900px;
  padding: 0 40px;
  margin-top: 24px;
}

.token-authn-payment-form__iframe {
  border: none;
  border-radius: 10px;
  min-width: 50%;
  margin-right: 64px;
  padding-top: 24px;
}

.token-authn-payment-form__iframe--ceca {
  min-height: 220px;
}

.token-authn-payment-form__iframe--redsys {
  min-height: 325px;
}

.token-authn-payment-form__info {
  padding: 8px 0;
}

.token-authn-payment-form__title {
  margin-bottom: 16px;
}

.token-authn-payment-form__image {
  width: fit-content;
  margin-bottom: 16px;
}

.token-authn-payment-form__description {
  color: var(--smoked-70);
  margin-top: auto;
}

.token-authn-payment-form__notifier {
  margin-top: 24px;
}