.modal-content {
  position: relative;
  background-color: var(--white);
  margin: 0 auto;
  min-width: 360px;
  display: inline-block;
  position: relative;
  border-radius: 6px;
  box-shadow: var(--shadow-hard);
}

.modal-content .modal-content__close {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 14px;
  top: 14px;
  cursor: pointer;
  color: var(--smoked-70);
  font-size: 1rem;
}

.modal-content .modal-content__header {
  display: block;
  font-size: 1.375rem;
  font-weight: bold;
}

.modal-content .modal-content__title {
  font-size: 1.375rem;
  line-height: 1.3em;
}

.modal-content .modal-content__without-header {
  display: none;
}

.modal-content .modal-content__close-button--hidden {
  display: none;
}
