.map {
  width: 100%;
  height: 100%;
}

.gmnoprint {
  display: none;
}

.gm-style-cc {
  display: none;
}
