.compact-zip-checker {
  border-top: 1px solid var(--white-cream);
  padding-top: 1.5rem;
}

.compact-zip-checker__title {
  font-size: 1.37em;
  font-weight: bold;
  line-height: 1.7rem;
}

.compact-zip-checker__subtitle {
  margin-top: 0.6rem;
  line-height: 1.5rem;
  font-size: 1em;
}

.compact-zip-checker__form {
  margin-top: 1.5rem;
}

.compact-zip-checker__form .input-text {
  width: 57%;
  margin-right: 1%;
  margin-bottom: 0;
}

.compact-zip-checker__form .input-text input {
  height: 56px;
}

.compact-zip-checker__form .button-big {
  width: 42%;
  margin: 0;
}

.compact-zip-checker__form .button-big span {
  font-size: 1em;
}

.compact-zip-checker__store-links {
  margin-top: 1.5rem;
}

.compact-zip-checker__store-links .market-links__android {
  margin-right: 1rem;
}

.compact-zip-checker__button-mobile {
  margin-top: 1rem;
}

.compact-zip-checker__button-mobile span {
  height: 100%;
  padding-top: 8px;
  font-size: 1em;
}

.compact-zip-checker__subtitle--desktop,
.compact-zip-checker__form {
  display: none;
}

@media (min-width: 992px) {
  .compact-zip-checker__subtitle--desktop {
    display: block;
  }

  .compact-zip-checker__form {
    display: flex;
  }

  .compact-zip-checker__subtitle--mobile,
  .compact-zip-checker__button-mobile,
  .compact-zip-checker__store-links {
    display: none;
  }
}
