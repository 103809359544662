.my-regulars-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: inherit;
}

.my-regulars-empty .my-regulars-empty__content {
  margin: 80px auto;
  max-width: 420px;
  text-align: center;
}

.my-regulars-empty .my-regulars-empty__image {
  width: 136px;
  height: 136px;
}

.my-regulars-empty .my-regulars-empty__title {
  margin: 40px 0 16px;
}

.my-regulars-empty .my-regulars-empty__message {
  color: var(--smoked-70);
  margin: 0 0 26px;
}

.my-regulars-empty .button {
  width: 193px;
}

.my-regulars-empty .footer {
  padding: 24px;
}

@media (min-width: 480px) {
  .my-regulars-empty .footer {
    max-width: calc(var(--max-cell-width) * 3);
  }
}

@media (min-width: 992px) {
  .my-regulars-empty .footer {
    max-width: calc(var(--max-cell-width) * 4);
  }
}

@media (min-width: 1200px) {
  .my-regulars-empty .footer {
    max-width: calc(var(--max-cell-width) * 5);
  }
}

@media (min-width: 1440px) {
  .my-regulars-empty .footer {
    max-width: calc(var(--max-cell-width) * 6);
  }
}
