.account {
  text-align: right;
  color: var(--smoked-70);
  cursor: pointer;
  position: relative;
  margin-right: 27px;
}

.account:hover {
  color: var(--black);
}

.account .account__title {
  padding: 0 10px;
  width: 120px;
  max-height: 3rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-font-smoothing: antialiased;
}

.account .ui-icon {
  cursor: pointer;
  font-size: 1rem;
  line-height: 0.8rem;
  position: absolute;
  top: 1px;
  right: -9px;
}

:focus > .account {
  outline: none;
  color: var(--black);
}
