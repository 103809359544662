.slots-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 414px;
  height: 417px;
  padding: 40px;
  text-align: center;
  margin: 0 auto;
}

.slots-placeholder-image {
  width: 120px;
}

.slots-placeholder-subtitle {
  color: var(--smoked-70);
}
