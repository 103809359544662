.checkout-summary {
  position: sticky;
  top: 12px;
}

.checkout-summary__title {
  margin: 0 0 8px;
}

.checkout-summary__subtotals {
  margin-bottom: 16px;
}

.checkout-summary__subtotals-products,
.checkout-summary__subtotals-delivery,
.checkout-summary__total,
.checkout-summary__taxes {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkout-summary__subtotals-products {
  margin-bottom: 8px;
}

.checkout-summary__subtotals-subtitle {
  width: 200px;
  color: var(--smoked-70);
  margin-top: 4px;
}

.checkout-summary__total {
  align-items: center;
  margin-bottom: 8px;
}

.checkout-summary__total > span:first-child {
  display: flex;
}

.checkout-summary__total-tooltip-icon {
  display: inline-flex;
  margin-top: 4px;
  margin-left: 8px;
}

.checkout-summary__taxes {
  margin-bottom: 24px;
}

.checkout-summary__payment-notifier {
  margin-bottom: 24px;
}

.checkout-summary .checkout-summary__terms-and-conditions {
  margin-top: 24px;
}

@media (max-width: 992px) {
  .boxed-layout__container {
    flex-direction: column;
    align-items: center;
  }

  .boxed-layout__sidebar {
    width: var(--content-width);
    margin-right: var(--content-sidebar-margin);
  }
}
