.empty-product {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.5em 0 2em 0;
  min-height: calc(100vh - var(--navbar-height) - var(--footer-height) - 2rem);
}

.empty-product--modal {
  min-height: 0;
  padding: 2.5em;
}

.empty-product__title {
  font-size: 1.375rem;
	font-weight: bold;
  line-height: 1.25;
  margin: 2rem 0 1rem 0;
}

.empty-product__subtitle {
  font-size: 1rem;
  line-height: 1.5;
  max-width: 15.3rem;
  text-align: center;
}

.empty-product__see_products {
  margin-top: 1.5em;
}

.empty-product__button {
  margin-top: 1.5em;
  width: 14em;
}
