.home-message {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  margin: 0 .5rem;
  height: 2.5rem;
  border-radius: 4px;
}

.home-message--warning {
  background-color: var(--egg-40);
  color: var(--chocolat)
}

.home-message--info {
  background-color: var(--white-cream-light);
  color: var(--blueberry)
}

.home-message__text {
  margin-left: 8px;
}
