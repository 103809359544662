.order-payment-list {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  border-radius: 8px;
}

.order-payment-list__title {
  margin-bottom: 8px;
}

.order-payment-list-add button {
  color: var(--cucumber);
  margin: 16px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.order-payment-list-add button:focus {
  outline: none;
  color: var(--cucumber-black-20);
}

.order-payment-list-add .ui-icon {
  font-size: 22px;
  padding-right: 4px;
}

.order-payment-list .order-payment-list-buttons {
  display: flex;
  justify-content: flex-end;
  border-top: 0.5px solid var(--white-cream);
  padding: 16px 0 0;
}

.order-payment-list-buttons .order-payment-list__cancel-button {
  margin-right: 10px;
  color: var(--smoked-70);
  font-weight: 600;
}

.order-payment-list-buttons .button {
  width: 120px;
}
