.address-cell__data {
  word-break: break-all;
}

.address-cell__street {
  margin: 0
}

.address-cell__comments {
  color: var(--smoked-70);
}

.address-cell__comments {
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3em;
}

.address-cell__map {
  position: absolute;
  top: 16px;
  left: 16px;
  height: 48px;
}
