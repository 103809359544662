.shopping-list-detail-header__wrapper {
  border-bottom: 1px solid var(--smoked-20);
  padding-bottom: 40px;
  margin-top: 48px;
  margin-bottom: 24px;
  margin-left: 12px;
  width: 100%;
}

.shopping-list-detail-header__actions-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.shopping-list-detail-header__product-info-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 12px;
}

.shopping-list-detail-header__name {
  margin-bottom: 8px;
}

.shopping-list-detail-header__name::first-letter {
  text-transform: capitalize;
}

.shopping-list-detail-header__products-quantity {
  color: var(--smoked-70);
}
