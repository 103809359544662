.payment-card-icon {
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.payment-card-icon.payment-card-icon__unknown {
  background-image: url('./assets/credit-card.png');
}

.payment-card-icon.payment-card-icon__visa {
  background-image: url('./assets/visa.png');
}

.payment-card-icon.payment-card-icon__mastercard {
  background-image: url('./assets/mastercard.png');
}

.payment-card-icon.payment-card-icon__maestro {
  background-image: url('./assets/maestro.png');
}
