.search-results {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.search-results__header {
  width: 100%;
  padding: 2px 4px 8px;
  color: var(--smoked-70);
}
