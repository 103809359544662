.sca-checkout-modal {
  margin: 32px 0;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.sca-checkout-modal__info {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.sca-checkout-modal__info:first-child {
  margin-top: 0px;
}

.sca-checkout-modal__image {
  width: 80px;
  height: 80px;
  margin-right: 24px;
}
