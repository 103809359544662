.card {
  border: solid 1px var(--white-cream);
  border-radius: 8px;
  padding: 24px;
  box-shadow: var(--shadow-card-1);
  transition: all 0.1s linear;
}

.card.card--hover:hover {
  box-shadow: var(--shadow-card-1-active);
}
