.image-zoomer {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row-reverse
}

.image-zoomer__source,
.image-overlay__image {
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 500px;
  margin: 0 auto;
}

.image-zoomer__image-overlay {
  position: absolute;
  background-color: var(--black);
  border-radius: 8px;
  width: 100%;
  height: 100%;
  opacity: 0.03;
  display: flex;
}

.image-zoomer--big {
  border-radius: 8px;
}

.image-zoomer__source {
  position: relative;
}

.image-zoomer__source > img {
  width: 100%;
  display: block;
}

.image-zoomer__cursor {
  cursor: auto;
}

.image-zoomer__cursor--magnifizer {
  cursor: pointer;
  cursor: zoom-in;
  cursor: url('../assets/magnifizer.svg') 14 12.85, auto;
}

.image-zoomer__cursor--decreaser {
  cursor: pointer;
  cursor: zoom-in;
  cursor: url('../assets/decreaser.svg') 14 12.85, auto;
}

.image-zoomer--big {
  width: 100%;
  height: 100%;
  display: none;
  background-repeat: no-repeat;
  background-color: var(--white);
  position: absolute;
}

@media (min-width: 1201px) {
  .image-zoomer__source,
  .image-overlay__image {
    max-width: 500px;
  }

}

@media (max-width: 991px) {
  .image-zoomer__image-overlay{
    height: 500px;
  }
} 
