.cart-button__overlay {
  position: fixed;
  left: 0;
  top: 0;
  background-color: var(--smoked-20);
  width: 100%;
  height: 100%;
}

.cart-button__overlay .cart-button__pop-over {
  position: absolute;
  top: 18px;
  right: 24px;
}

.cart-button__overlay .cart-button__pop-over .pop-over {
  position: absolute;
  top: 100%;
  margin-top: 16px;
  right: 78px;
  animation-name: fade-popover;
  animation-duration: 0.3s;
  animation-timing-function: ease;
}

.cart-button__overlay .cart-button__pop-over .cart-button {
  box-shadow: var(--shadow-hard);
}

@keyframes fade-popover {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
