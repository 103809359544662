.strength-bar {
  height: 38px;
  margin-top: 4px;
  width: 100%;
}

.strength-bar .strength-bar__level-indicator {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
}

.strength-bar .strength-bar__level-indicator .strength-bar__level {
  flex: 1;
  height: 4px;
  background-color: var(--smoked-20);
  border-radius: 2px;
}

.strength-bar
  .strength-bar__level-indicator
  .strength-bar__level.strength-bar__level--invalid,
.strength-bar
  .strength-bar__level-indicator
  .strength-bar__level.strength-bar__level--weak {
  margin-right: 4px;
}

.strength-bar .strength-bar__message {
  line-height: 1.36;
  font-weight: normal;
  text-align: left;
}

.strength-bar.strength-bar--safe .strength-bar__level {
  background-color: var(--cucumber);
}

.strength-bar.strength-bar--safe .strength-bar__message {
  color: var(--cucumber);
}

.strength-bar.strength-bar--weak .strength-bar__level--invalid,
.strength-bar.strength-bar--weak .strength-bar__level--weak {
  background-color: var(--orange);
}

.strength-bar.strength-bar--weak .strength-bar__message {
  color: var(--orange);
}

.strength-bar.strength-bar--invalid .strength-bar__level--invalid {
  background-color: var(--tomato);
}

.strength-bar.strength-bar--invalid .strength-bar__message {
  color: var(--tomato);
}

.strength-bar.strength-bar--wrong-format .strength-bar__message {
  color: var(--tomato);
}
