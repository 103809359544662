.payment-card-summary {
  display: flex;
  align-items: center;
}

.payment-card-summary__icon {
  width: 48px;
  height: 48px;
  margin-right: 8px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.payment-card-summary__icon.payment-card-summary__icon--unknown {
  background-image: url('./assets/credit-card.png');
}

.payment-card-summary__icon.payment-card-summary__icon--visa {
  background-image: url('./assets/visa.png');
}

.payment-card-summary__icon.payment-card-summary__icon--mastercard {
  background-image: url('./assets/mastercard.png');
}

.payment-card-summary__icon.payment-card-summary__icon--maestro {
  background-image: url('./assets/maestro.png');
}

.payment-card-summary__card-mask {
  margin-right: 16px;
}

.payment-card-summary__card-expiration--valid,
.payment-card-summary__card-expiration--expires-soon {
  color: var(--smoked-70);
}

.payment-card-summary__card-expiration--expired {
  color: var(--tomato);
}
