.address-layout__header {
  margin-bottom: 16px;
  border-bottom: 1px solid var(--smoked-20);
  padding-bottom: 16px;
}

.address-list__layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media (max-width: 992px) {
  .address-list__layout {
    padding: 0 10px;
  }
}
