.banner-item {
  height: 248px;
  min-width: auto;
  width: 100%;
  max-width: 1298px;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
}

.banner-item a {
  width: 100%;
  height: 100%;
  display: block;
}

.banner-item__left {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 450px;
  min-width: 450px;
  height: 100%;
  padding: 40px 40px;
  border-radius: 8px 0 0 8px;
}

.banner-item__left button {
  color: #000000;
  text-align: center;
  width: 146px;
  height: 36px;
  border-radius: 20px;
  letter-spacing: 0.5px;
  font-size: 1rem;
}

.banner-item .banner-item__title {
  font-family: 'PT Serif';
  width: 292px;
  margin: 0;
  text-align: left;
}

.banner-item__background {
  width: 100%;
  height: 100%;
  min-width: 250px;
}

.banner-item__image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
