.header {
  background-color: var(--white);
  height: 76px;
  display: flex;
  padding: 0 24px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid var(--white-cream);

  /* TODO: Only for compatibility with the old layout */
  top: 0;
  width: 100%;
  position: fixed;
}

.header .header__left {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
}

.header .header__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
