
.edit-order-products__cart {
  display: flex;
  flex-direction: column;
  width: calc(var(--cart-width) + 2rem);
  padding: 0 1rem 1rem;
  margin-left: auto;
  background-color: var(--white-cream-light);
  border-left: 0.5px solid var(--white-cream);
}

.edit-order-products__cart-message {
  margin: 1.5rem 0 1rem;
}

.edit-order-products__cart .cart-product-list {
  border-radius: .5rem;
  background-color: var(--white);
  box-shadow: var(--shadow-label);
}

.edit-order-products__sorting-method {
  padding-bottom: 1.25rem;
  display: flex;
}

.edit-order-products__actions {
  display: flex;
  margin-top: 3.5rem;
}

.edit-order-products__actions button:not(:last-child) {
  margin-right: .5rem;
}